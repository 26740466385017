.author-block-wrapper{
    position: relative;
}
.author-block{
    display: flex;
    padding: .3rem;
    align-items: flex-start;
    color: #fff;
    background: #415157 linear-gradient(0deg,#3D4D51 0%,#4C595E 30%, #657177 70%, #758084 100%) repeat-x;
    &__image{
        margin-right: 1rem;
        width: 40%;
    }
    &__text{
        flex: 1;
        margin-top: 3rem;
        max-height: 4.5rem;
        overflow: hidden;
        text-shadow: 0 -1px #000;
        line-height: 1.2rem;
    }
    &__title{
        position: absolute;
        top: 1.5rem;
        left: 35%;
        background-color: $primary;
        padding: .1rem .4rem;
        text-transform: uppercase;
        text-shadow: 0 -1px #404040;
        color: #fff;
    }
    &:hover{
        color: $gray-300;
        text-decoration: none;
    }
}

.author-reverse{
    .author-block{
        &__text{
            margin-top: 0.5rem;
        }
        &__title{
            top: auto;
            left: 15%;
            bottom: .5rem;
            padding: .1rem .4rem;
            font-size: .8rem;
            text-transform: capitalize;
        }
    }
}

.author-rate{
    color: #fff;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: .8em solid #fff;
        border-left: .8em solid transparent;
    }
    &:after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom: .8em solid #5C656D;
        border-right: .8em solid transparent;
    }
    h4{
        text-transform: uppercase;
        padding-top: .8rem;
        padding-left: 1rem;
        padding-right: 1.2rem;
        font-weight: 700;
        font-size: 1.3rem;
    }
    .nav{
        font-size: .9rem;
        text-transform: uppercase;
        font-weight: 700;
        .nav-link{
            color: #5D656E;
            padding: .9rem .6rem;
            &.active{
                color: #fff;
            }
        }
    }
    .tab-content{
        border-top: 3px #5d666f solid;
        flex-basis: 100%;
    }
}

.top-items{
    @include list-unstyled();
    &__item{
        a{
            padding: .7rem 2rem;
            display: block;
            color: $white;
            &:hover{
                text-decoration: none;
                background-color: #ddd;
                color: #363636;

            }
        }
        &:not(:last-child){
            a{
                border-bottom: #3D454F solid 1px;
            }
        }
    }
    &__item-top{
        a{
            color: $primary;
        }
    }
}

.video-block{
    color: $white;
    .nav{
        font-size: 1.3rem;
        font-weight: 700;
        .nav-link{
            color: #5D656E;
            padding: .9rem .6rem;
            &.active{
                color: $white;
            }
        }
    }
    .accordion-view__item[aria-expanded="true"] {
        display: none;
    }
    .accordion-view{
        padding: .5rem;
        &__item{
            color: $white;
            display: block;
            margin: .5rem 0;
            i{
                margin-right: 1rem;
                margin-left: .5rem;
                color: #5d656e;
            }
            &:hover{
                color: #5d656e;
            }
        }
        &__video{
            background-color: #000;
            height: 250px;
        }
        &__photo{
            position: relative;
            &-text{
                color: $white;
                position: absolute;
                background-color: rgba(65, 81, 87, 0.5);
                font: 1.4rem Georgia;
                padding: 1rem 1.5rem;
                bottom: 0;
                left: 0;
                line-height: 1.6rem;
                width: 100%;
                &:hover{
                    color: $gray-300;
                }
            }
            img{
                width: 100%;
            }
        }
    }
}

.smi{
    background: url(../img/smi.jpg) no-repeat scroll 0 0 transparent;
    background-size: cover;
    border: 1px $gray-800 solid;
    &__item{
        margin: 0 .4rem;
        border-top: 1px $gray-800 solid;
        padding: .7rem 0rem;
        color: $gray-800;
        img{
            border: 1px $gray-800 solid;
            width: 20%;
        }
        h5{
            font-size: .9rem;
            font-weight: 700;
            color: $gray-800;
        }
    }
}