.carousel-indicators{
    li{
        opacity: 1;
    }
    .active{
        background-color: $primary;
    }
}
.carousel-caption{
    background-color: rgba(65,81,87,.8);
}