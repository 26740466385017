header{
    background: url(../img/header.gif) no-repeat center center, 
    linear-gradient(0deg, #364451 0%, #364451 50%, #343E4A 50%, #343E4A 100%);
}
.navbar-top{
    background: #496070 linear-gradient(180deg,#586e7c,#3e596c) repeat-x;
    font-size: .85rem;
    i{
        color: $primary;
    }
    .nav-item{
        font-weight: 300;
    }
    .nav-link{
        padding: .55rem $nav-link-padding-x;
        color: #fff;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}

.nav-main{
    padding: 0 .5rem;
    background: #98989a linear-gradient(0deg,#939395 0%, #99999b 35%, #bbbcbe 70%, #dbdcde 100%) repeat-x;
    .nav-link{
        padding: .83rem 1rem;
        font-size: .75rem;
        text-transform: uppercase;
        color: #232528;
        text-shadow: 0 1px #bfc0c2;
        font-weight: 600;
        position: relative;
        transition: ease-in-out 0.2s;
        &:hover{
            background: #b5b6b7 linear-gradient(0deg,#b5b6b7 0%,#adaeb0 30%, #afb0b1 70%, #b7b8ba 100%) repeat-x;
        }
        &:not(:last-child):after {
            background: #babbbd;
            width: 1px;
            content: "";
            display:block;
            position: absolute;
            top:.83rem;
            bottom: 0;
            right: -.5rem;
            height: 1rem;
        }
    }
    .active{
        color: $primary;
    }
    .nav-icon{
        padding-top: .45rem;
        margin-right: .3rem;
        //padding-bottom: .3rem;
        .btn{
            width: 2rem;
            border: transparent;
        }
        .btn-{
            &tw:hover{
                @include gradient-y(#B0E1F7,#9FD9F5)
            }
            &fb:hover{
                @include gradient-y(#7590C9,#4E78C8)
            }
            &vk:hover{
                @include gradient-y(#3E85B0,#2B72A0)
            }
        }
        
    }
}

.nav-scroller {
    position: relative;
    z-index: 2;
    overflow-y: hidden;
    height: 2.75rem;
}

.nav-scroller .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.rulaw{
    background: $primary;
    color: #fff !important;
    display: inline-block;
    text-align: center;
    line-height: 1.9rem;
    width: 2rem;
    height: 2rem;
    font-weight: 600;
}

.logo{
    display: inline-block;
    height: 86px;
    width: 100%;
    padding: 10px 0;
    text-align: center;
}
.top-reklama{
    text-align: right;
    height: 86px;
}