.nav-footer{
    .nav-link{
        color: $gray-900;
        font-weight: 700;
        font-size: .82rem;
        text-decoration: underline;
        &:hover{
            color: $primary;
            text-decoration: none;
        }
    }
}
.copywrong,
.footer-info{
    font-size: .8rem;
}
.nav-footer-ext{
    font-size: .7rem;
    .nav-link{
        padding: .2rem .3rem;
        color: $primary;
        font-weight: 700;
    }
}