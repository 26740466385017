.main-container{
    margin-top: -2rem;
}
.content-main,
.content-sidebar {
    background: #fff;
    padding-left: 3px;
    padding-right: 3px;
    height: 100%;
}
.layout-pr-1{
    padding-right: .25rem!important;
}
.layout-pl-0{
    padding-left: 0!important;
}

.block-wrapper{
    display: flex;
    &__item{
        flex: 1;
        margin-right: .5rem;
        margin-bottom: 1rem;
        &:last-child{
            margin-right: 0;
        }
    }
   
}
@include media-breakpoint-down(md) {
    .layout-pr-1{
        padding-right: 1rem!important;
    }
    .layout-pl-0{
        padding-left: 1rem!important;
        margin-top: 1rem;
    }
}
@include media-breakpoint-down(sm) {
    .block-wrapper{
        flex-direction: column;
    }
}

h1{
    font-family: Georgia;
}