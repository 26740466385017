$fa-font-path:                "../fonts" !default;

$enable-gradients: true;

$font-stack: Roboto, "Open Sans", "Helvetica Neue", sans-serif;
$font-family-sans-serif:  Arial, sans-serif, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$primary: #be021b;
$secondary: #343e4a;
$font-size-base: 1rem;
$body-color: #333;
$primary-gradient: $primary linear-gradient(0deg,#bf0101,#c40000, #cc0000) repeat-x;

$navbar-padding-y: .1rem;
$navbar-padding-x: .5rem;

$carousel-indicator-active-bg: #627286;
//$carousel-indicator-width: 15px;
//$carousel-indicator-height: 15px;
$pagination-border-width: 0;
$pagination-hover-bg: $primary;
$pagination-hover-color: white;
$pagination-hover-border-color: transperent;

$dropdown-link-hover-bg: $secondary;
$dropdown-link-hover-color: #fff;
$dropdown-link-active-bg: $secondary;

$input-btn-focus-width: 0;