@import "_variables";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";

@import "~bootstrap/scss/bootstrap";
@import "components";
@import "carousels";
@import "header";
@import "footer";
@import "layout";
@import "sidebar";
@import "vote";
@import "news";


@font-face {
  font-family: "Roboto";
  font-style: normal;
  //font-weight: 400;
  src: url(../fonts/Roboto-Regular.ttf);
}

body {
  background: #dbdee3 url(../img/bg.png) repeat scroll 0 0;
  background-attachment: fixed;
}
