.block-alert{
    min-height: 3rem;
    display: flex;
    flex-wrap: nowrap;
    //justify-content: space-between;
    color: $white;
    text-shadow: 0 -1px #000;
    background: $gray-800 linear-gradient(0deg,#394145,#515d60) repeat-x;
    &__title{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $primary-gradient;
        font: 2.5rem Georgia;
        text-transform: uppercase;
        padding: .7rem .5rem;
        &:after{
            clip-path: polygon(0% 0%, 50% 50%, 0% 100%);
            content: '';
            position: absolute;
            height: 100%;
            width: 4rem;
            top: 0;
            right: -3.90rem;
            background: $primary-gradient;
        }
    }
    &__text{
        display: flex;
        align-items: center;
        background-color: $gray-800;
        background: $gray-800 linear-gradient(0deg,#394145,#515d60) repeat-x;
        font: 1.5rem Georgia;
        padding-left: 3rem;
        line-height: 1.3rem;
    }
    &:hover{
        color: $gray-300;
        text-decoration: none;
    }
}

.page-link{
    &:hover {
        background: $primary-gradient;
        &:after{
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #e04c4d;
        }
    }
}
.page-item{
    &.active .page-link{
        background: $primary-gradient;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #e04c4d;
        }
    }
}

.btn-main{
    @include button-variant(#cd0100,#cc0000);
    position: relative;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #e04c4d;
    }
}

.btn-secondary{
    @include button-variant(#f0f0f0, transparent);
    border-bottom: 1px #d7d7d7 solid !important;
    text-shadow: 0 1px 0 #FFFFFF;
}

.dropdown-toggle:after{
    color: $primary;
}