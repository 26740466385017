.news-item{
    margin: 1rem 0;
    //@include transition(all .6s ease);
    &__link{
        color: $gray-800;
        &:hover{
            color: $primary;
        }
    }
    &__image{
        margin-right: 1rem;
        flex-basis: 25%;
        img{
            width: 100%;
        }
    }
    &__meta{
        color: $gray-500;
        font-size: .7rem;
        margin: 0;
    }
    &__type{
        background-color: $primary;
        padding: .3rem .5rem;
        margin: 0 1rem 0 2rem;
        color: $white;
        text-transform: uppercase;
        position: relative;

        &:before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-top: .5em solid $white;
            border-left: .5em solid transparent;
        }
        &:after{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-bottom: .5em solid lighten($primary, 40%);
            border-right: .5em solid transparent;
        }

        &:hover{
            color: $white;
            background-color: $gray-800;
            text-decoration: none;

            &:after{
                border-bottom: .5em solid lighten($gray-800, 40%);
            }
        }
    }
    &__author{
        color: $primary;
        font-size: .8rem;
        font-weight: 700;
        &:hover{
            color: $gray-800;
            text-decoration: none;
        }
    }
    &__caption{
        font-family: Georgia;
        margin-top: .2rem;
        margin-bottom: .4rem;
    }    
    &__text{
        font-size: .85rem;
        line-height: 1rem;
        margin-bottom: .3rem;
    }
    &__stats{
        font-size: .8rem;
        a{
            color: $gray-600;
            margin-right: .7rem;
            &:hover{
                color: $primary;
                text-decoration: none;
            }
        }
        .fas{
            margin-right: .3rem;
        }
    }
}
@include media-breakpoint-down(sm) {
    .news-item{
        flex-direction: column-reverse;
        &__image{
            margin-right: 0rem;
            width: 100%;
            //flex-basis: 100%;
        }
    }
}
.news-detail{
    &__meta{
        color: #afafaf;
        font-size: .8rem;
    }
    &__img{
        margin-bottom: 1.5rem;
    }
    &__img-small{
        width: 40%;
        float: left;
        margin-right: 1.5rem;
    }
    &__description{
        display: inline;
        font-weight: 700;
        //margin-bottom: 1.5rem;
    }
    &__text{
        margin-top: 1.5rem;
        ul{
            list-style: none;
            //@include list-unstyled();
            li{
                &::before {
                    content: "•"; color: $primary;
                    display: inline-block; 
                    width: 1em;
                    margin-left: -1em
                }
            }
            
        }
    }
    &__stats{
        font-size: .8rem;
        .fas{
            //padding: 0 .5rem 0 .9rem;
            padding-right: .4rem;
        }    
    }
    &__views{
        margin-right: .9rem;
        &:hover{
            color: $primary;
            cursor: pointer;
        }
    }
}